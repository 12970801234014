import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { pathname } = useLocation();
  const [redirect, setRedirect] = useState('/');

  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  useEffect(() => {
    if (pathname.includes('auth')) setRedirect('/');
    if (pathname.includes('admin')) setRedirect('/admin');
    if (pathname.includes('agent')) setRedirect('/agent');
  }, [pathname]);

  const logo = (
    <Box sx={{ width: 75, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1152.00 389.00">
        <path
          fill="#990104"
          d="
  M 35.75 164.25
  A 2.64 2.60 62.5 0 0 35.34 165.59
  Q 35.11 171.56 35.27 177.37
  C 35.76 195.76 35.53 214.22 35.47 232.57
  Q 35.47 234.00 34.03 234.00
  L 3.00 234.00
  A 1.76 1.76 0.0 0 1 1.24 232.24
  L 1.24 3.03
  A 2.45 2.45 0.0 0 1 3.65 0.58
  Q 30.76 0.12 58.25 0.22
  Q 59.23 0.22 60.25 0.23
  Q 83.26 0.42 106.25 1.26
  Q 121.43 1.82 135.66 6.45
  C 165.87 16.27 185.36 38.96 189.46 70.98
  Q 191.29 85.24 188.77 98.98
  C 181.67 137.76 147.27 159.67 109.64 161.90
  Q 73.53 164.04 38.26 162.88
  Q 36.62 162.83 35.75 164.25
  Z
  M 35.62 32.30
  L 35.28 130.70
  A 2.51 2.51 0.0 0 0 37.78 133.22
  L 101.71 133.44
  A 54.72 49.58 0.2 0 0 156.60 84.05
  L 156.62 79.79
  A 54.72 49.58 0.2 0 0 102.07 30.02
  L 38.14 29.80
  A 2.51 2.51 0.0 0 0 35.62 32.30
  Z"
        />
        <path
          fill="#990104"
          d="
  M 350.13 156.05
  Q 350.09 156.97 350.62 157.72
  Q 371.13 186.57 402.97 231.29
  Q 403.23 231.67 403.18 232.12
  L 403.04 233.20
  A 1.28 1.27 4.4 0 1 401.73 234.32
  Q 389.02 233.89 376.35 233.99
  C 374.17 234.01 371.52 233.65 368.99 233.77
  Q 367.63 233.83 366.28 231.94
  Q 343.83 200.41 317.89 163.64
  A 3.20 3.20 0.0 0 0 315.29 162.28
  Q 281.82 162.09 247.33 162.45
  A 2.59 2.59 0.0 0 0 244.76 165.04
  L 244.77 231.69
  A 2.24 2.24 0.0 0 1 242.55 233.93
  L 213.37 234.20
  A 1.85 1.85 0.0 0 1 211.51 232.35
  L 211.51 1.99
  A 1.35 1.35 0.0 0 1 212.82 0.64
  C 223.02 0.37 233.12 0.61 243.42 0.26
  C 248.93 0.07 254.39 0.49 259.89 0.49
  Q 273.53 0.49 287.03 0.52
  C 288.34 0.52 289.68 0.82 290.92 0.79
  C 297.87 0.57 304.24 0.90 310.99 0.95
  C 351.13 1.29 388.67 20.93 397.84 62.06
  Q 402.41 82.54 397.64 103.37
  C 392.09 127.60 374.68 146.13 351.22 154.57
  A 1.68 1.67 81.5 0 0 350.13 156.05
  Z
  M 244.75 32.08
  L 244.75 131.64
  A 2.05 2.05 0.0 0 0 246.80 133.69
  L 312.48 133.69
  A 53.41 47.60 0.0 0 0 365.89 86.09
  L 365.89 77.63
  A 53.41 47.60 0.0 0 0 312.48 30.03
  L 246.80 30.03
  A 2.05 2.05 0.0 0 0 244.75 32.08
  Z"
        />
        <path
          fill="#990104"
          d="
  M 419.65 18.96
  C 449.07 -2.45 486.16 -6.01 518.42 10.49
  C 545.91 24.55 575.17 31.69 605.02 24.96
  C 606.43 24.64 608.11 24.70 609.54 24.78
  A 1.04 1.03 -74.7 0 1 610.40 26.29
  Q 609.53 27.98 608.03 29.08
  Q 566.42 59.44 518.44 42.46
  Q 512.11 40.22 503.64 35.88
  C 491.52 29.67 478.19 25.18 464.72 22.80
  Q 441.41 18.69 418.07 24.49
  A 0.89 0.88 -2.7 0 1 416.98 23.50
  Q 417.44 20.57 419.65 18.96
  Z"
        />
        <path
          fill="#990104"
          d="
  M 657.93 62.85
  Q 656.51 62.79 656.51 64.21
  L 656.51 232.25
  A 1.76 1.75 0.0 0 1 654.75 234.00
  L 623.79 234.00
  Q 622.32 234.00 622.32 232.50
  L 621.98 2.30
  A 1.79 1.79 0.0 0 1 623.76 0.51
  Q 635.08 0.44 645.79 0.52
  C 650.69 0.56 652.62 2.97 656.34 6.83
  Q 685.15 36.77 714.60 67.40
  C 715.74 68.57 716.85 68.79 718.53 68.51
  Q 753.25 62.69 779.86 85.89
  C 789.14 93.97 793.30 102.82 794.78 114.61
  Q 795.52 120.55 795.96 125.92
  Q 796.16 128.27 795.02 130.44
  A 2.09 2.09 0.0 0 1 793.11 131.55
  Q 790.36 131.45 788.02 129.62
  C 774.06 118.72 759.63 108.07 741.84 104.40
  Q 735.18 103.03 727.07 102.95
  C 715.54 102.82 703.44 102.15 693.32 96.56
  Q 687.71 93.46 678.31 83.77
  Q 667.79 72.93 659.40 63.88
  Q 658.48 62.87 657.93 62.85
  Z"
        />
        <path
          fill="#036137"
          d="
  M 815.80 171.76
  Q 817.25 172.52 817.25 170.88
  Q 817.25 119.46 817.22 67.25
  Q 817.22 66.27 817.21 65.25
  Q 817.00 33.82 817.00 2.36
  A 1.62 1.62 0.0 0 1 818.61 0.74
  L 849.74 0.43
  A 1.31 1.31 0.0 0 1 851.07 1.74
  L 850.72 231.47
  A 2.31 2.31 0.0 0 1 848.40 233.77
  Q 837.27 233.73 825.80 233.78
  Q 824.12 233.79 822.84 232.67
  Q 819.71 229.94 816.81 226.93
  Q 785.11 194.01 753.38 161.12
  C 752.39 160.10 751.24 159.48 749.73 159.46
  Q 746.30 159.41 742.76 159.23
  C 712.12 157.71 684.84 139.11 680.42 107.08
  C 679.78 102.43 682.71 101.49 686.29 103.46
  C 696.11 108.86 706.66 111.34 717.81 111.46
  C 739.89 111.69 759.21 118.21 776.70 132.54
  C 789.80 143.27 802.05 156.90 813.77 170.05
  Q 814.86 171.27 815.80 171.76
  Z"
        />
        <path
          fill="#036137"
          d="
  M 1099.45 141.92
  C 1090.40 191.50 1048.01 226.74 998.73 232.88
  Q 992.05 233.71 978.77 233.77
  Q 963.58 233.84 950.00 233.72
  Q 948.49 233.71 947.00 233.70
  Q 916.01 233.50 878.75 233.50
  A 2.07 2.07 0.0 0 1 876.68 231.42
  L 877.53 2.36
  A 1.63 1.62 -1.1 0 1 879.10 0.74
  Q 883.43 0.56 887.51 0.55
  Q 917.20 0.48 948.25 0.57
  Q 949.35 0.57 950.49 0.60
  Q 963.88 0.95 976.76 0.69
  Q 988.52 0.46 996.76 1.43
  Q 1041.60 6.72 1071.16 37.63
  Q 1099.28 67.03 1101.16 108.22
  Q 1101.50 115.48 1101.17 125.13
  Q 1100.86 134.23 1099.45 141.92
  Z
  M 910.13 32.11
  L 910.73 202.12
  A 2.27 2.27 0.0 0 0 913.01 204.39
  L 980.40 204.15
  A 88.19 85.91 -0.2 0 0 1068.29 117.93
  L 1068.28 115.19
  A 88.19 85.91 -0.2 0 0 979.80 29.59
  L 912.40 29.83
  A 2.27 2.27 0.0 0 0 910.13 32.11
  Z"
        />
        <rect fill="#036137" x="1119.24" y="0.50" width="31.76" height="233.50" rx="1.85" />
        <path
          fill="#990104"
          d="
  M 587.54 119.87
  C 593.72 119.57 599.79 118.23 605.84 117.36
  Q 607.68 117.09 609.74 117.28
  A 1.40 1.39 20.1 0 1 610.76 119.47
  C 610.16 120.32 609.40 121.27 608.58 121.93
  C 582.18 142.79 545.11 146.63 514.52 133.50
  Q 510.96 131.97 501.91 127.40
  Q 484.32 118.53 462.75 115.29
  Q 441.16 112.05 419.79 117.44
  A 1.47 1.47 0.0 0 1 417.96 116.09
  Q 417.84 113.49 419.88 111.91
  Q 447.43 90.75 483.27 93.35
  Q 500.43 94.60 513.51 100.51
  Q 524.67 105.55 534.96 110.16
  Q 559.66 121.23 587.54 119.87
  Z"
        />
        <path
          fill="#990104"
          d="
  M 561.87 211.35
  C 576.20 214.15 591.78 213.43 606.28 210.31
  Q 608.14 209.91 609.97 210.47
  A 1.12 1.12 0.0 0 1 610.46 212.30
  Q 607.84 215.11 604.52 217.34
  C 578.33 234.94 546.89 239.45 517.56 227.54
  C 508.55 223.88 499.70 218.99 489.94 215.38
  C 466.34 206.66 443.06 203.56 419.27 209.68
  Q 418.53 209.87 417.78 209.77
  Q 415.95 209.53 417.10 208.09
  C 425.87 197.11 441.44 190.15 454.96 187.63
  Q 478.46 183.26 499.82 189.01
  C 513.15 192.59 527.07 199.79 539.94 205.10
  Q 548.97 208.82 561.87 211.35
  Z"
        />
        <path
          fill="#000000"
          d="
  M 899.25 278.38
  C 908.79 277.42 919.85 280.03 927.34 283.86
  C 941.16 290.94 955.35 294.62 970.72 290.59
  A 1.83 1.83 0.0 0 1 972.97 291.99
  Q 973.30 293.55 971.89 294.54
  Q 959.35 303.32 944.95 303.39
  C 934.28 303.43 924.56 300.18 914.90 295.42
  C 906.12 291.09 895.36 288.46 885.58 289.61
  C 882.28 289.99 877.52 291.10 874.32 290.27
  A 0.78 0.77 18.0 0 1 873.79 289.24
  C 873.93 288.87 874.25 288.29 874.55 288.05
  Q 885.25 279.79 899.25 278.38
  Z"
        />
        <rect
          fill="#000000"
          x="-10.12"
          y="-9.75"
          transform="translate(190.12,294.31) rotate(-0.5)"
          width="20.24"
          height="19.50"
          rx="2.35"
        />
        <path
          fill="#000000"
          d="
  M 408.75 302.76
  A 1.50 1.50 0.0 0 0 407.25 304.26
  L 407.25 361.76
  A 1.69 1.68 3.7 0 0 408.72 363.43
  Q 410.30 363.63 411.74 363.64
  Q 439.41 363.85 466.13 363.72
  A 2.60 2.60 0.0 0 0 468.71 361.00
  L 468.04 345.76
  A 2.63 2.63 0.0 0 1 471.26 343.08
  Q 480.30 345.19 489.06 347.32
  Q 492.11 348.06 492.14 351.19
  Q 492.24 360.65 490.87 368.29
  C 488.51 381.46 479.95 382.85 467.99 382.82
  Q 434.46 382.72 405.12 382.77
  Q 402.47 382.78 399.40 382.26
  C 384.90 379.85 383.83 368.78 383.96 356.57
  Q 384.01 351.49 383.87 346.58
  Q 383.59 336.70 383.84 323.25
  Q 384.05 311.24 384.25 302.04
  Q 384.55 288.02 398.00 285.51
  Q 403.17 284.55 407.99 284.78
  C 409.18 284.84 410.18 284.51 411.35 284.50
  Q 432.80 284.41 453.00 284.50
  C 453.65 284.50 454.40 284.75 455.21 284.75
  Q 463.45 284.76 473.14 284.89
  C 482.79 285.02 489.09 288.44 490.93 298.37
  Q 492.39 306.22 491.59 315.24
  Q 491.37 317.63 488.96 317.97
  Q 480.51 319.17 471.92 320.04
  Q 467.93 320.44 468.16 316.43
  L 468.79 305.48
  A 2.57 2.56 -88.3 0 0 466.23 302.76
  L 408.75 302.76
  Z"
        />
        <path
          fill="#990104"
          d="
  M 672.53 326.93
  C 684.69 327.11 696.47 326.80 708.44 326.76
  A 2.86 2.86 0.0 0 1 711.31 329.69
  L 710.93 343.98
  A 2.14 2.13 1.0 0 1 708.77 346.06
  L 673.04 345.71
  A 2.51 2.51 0.0 0 0 670.50 348.22
  L 670.51 380.00
  Q 670.51 383.00 667.50 383.00
  L 649.75 383.00
  Q 646.75 383.00 646.76 380.00
  L 647.08 286.94
  A 2.49 2.48 -89.9 0 1 649.56 284.46
  L 744.80 284.48
  A 2.52 2.51 0.7 0 1 747.32 287.05
  L 747.06 300.22
  A 2.04 2.03 0.4 0 1 745.03 302.21
  L 672.61 302.57
  A 2.10 2.10 0.0 0 0 670.52 304.63
  L 670.17 324.49
  A 2.40 2.40 0.0 0 0 672.53 326.93
  Z"
        />
        <path
          fill="#036137"
          d="
  M 824.79 329.34
  L 824.63 343.49
  A 2.36 2.36 0.0 0 1 822.24 345.82
  L 785.01 345.43
  A 2.36 2.36 0.0 0 0 782.62 347.83
  L 783.20 380.17
  A 2.36 2.36 0.0 0 1 780.89 382.57
  L 762.65 382.99
  A 2.36 2.36 0.0 0 1 760.24 380.63
  L 760.25 286.80
  A 2.36 2.36 0.0 0 1 762.62 284.44
  L 857.21 284.80
  A 2.36 2.36 0.0 0 1 859.56 287.21
  L 859.32 299.93
  A 2.36 2.36 0.0 0 1 856.96 302.25
  L 784.80 302.25
  A 2.36 2.36 0.0 0 0 782.44 304.63
  L 782.64 324.94
  A 2.36 2.36 0.0 0 0 785.02 327.28
  L 822.41 326.95
  A 2.36 2.36 0.0 0 1 824.79 329.34
  Z"
        />
        <path
          fill="#000000"
          d="
  M 248.03 363.75
  Q 283.57 363.74 314.96 363.77
  Q 315.57 363.77 316.18 363.62
  A 2.65 2.64 81.1 0 1 319.42 365.99
  Q 319.92 372.57 319.76 379.86
  A 2.97 2.96 0.6 0 1 316.79 382.76
  Q 272.93 382.77 229.17 382.75
  C 227.87 382.75 226.62 382.40 225.30 382.52
  A 2.56 2.55 87.3 0 1 222.50 379.97
  L 222.51 287.37
  A 2.62 2.62 0.0 0 1 225.13 284.75
  L 243.47 284.75
  A 2.46 2.46 0.0 0 1 245.93 287.22
  L 245.69 361.40
  A 2.34 2.34 0.0 0 0 248.03 363.75
  Z"
        />
        <path
          fill="#000000"
          d="
  M 608.74 381.01
  L 598.79 363.38
  A 3.03 3.02 -14.5 0 0 596.17 361.84
  L 537.95 361.50
  A 2.90 2.90 0.0 0 0 535.39 363.01
  L 525.47 381.27
  A 2.84 2.82 14.1 0 1 522.98 382.75
  L 504.80 382.77
  A 2.26 2.26 0.0 0 1 502.84 379.39
  L 556.03 286.30
  A 3.09 3.09 0.0 0 1 558.71 284.75
  L 578.05 284.75
  A 2.51 2.49 -14.9 0 1 580.22 286.00
  Q 607.00 332.33 633.95 378.94
  Q 635.00 380.76 633.99 382.61
  Q 633.74 383.07 633.21 383.05
  L 610.99 382.37
  A 2.71 2.69 76.0 0 1 608.74 381.01
  Z
  M 547.43 340.96
  A 1.40 1.40 0.0 0 0 548.65 343.03
  L 585.69 343.35
  A 1.40 1.40 0.0 0 0 586.94 341.31
  L 568.72 306.48
  A 1.40 1.40 0.0 0 0 566.25 306.46
  L 547.43 340.96
  Z"
        />
        <rect fill="#000000" x="180.00" y="315.50" width="20.00" height="67.26" rx="2.55" />
        <path
          fill="#000000"
          d="
  M 969.99 338.57
  Q 951.11 350.52 929.66 344.14
  C 922.67 342.06 916.57 338.31 909.92 335.89
  Q 896.59 331.04 881.37 333.30
  Q 878.60 333.71 874.91 333.30
  A 1.03 1.02 2.3 0 1 874.00 332.32
  Q 873.96 331.27 874.87 330.61
  Q 895.28 315.84 919.07 323.40
  C 925.38 325.41 931.25 328.86 937.47 331.22
  Q 950.67 336.23 966.91 333.83
  Q 969.96 333.38 972.83 333.97
  A 1.13 1.13 0.0 0 1 973.40 335.88
  Q 971.88 337.37 969.99 338.57
  Z"
        />
        <rect fill="#000000" x="0.76" y="326.24" width="153.24" height="14.26" rx="2.60" />
        <rect fill="#000000" x="999.99" y="326.24" width="151.26" height="14.26" rx="2.77" />
        <path
          fill="#000000"
          d="
  M 968.85 377.01
  Q 970.73 376.85 972.31 377.43
  A 1.12 1.11 -53.5 0 1 972.60 379.37
  C 959.31 389.38 941.94 391.35 926.46 385.86
  Q 921.80 384.21 915.91 381.30
  Q 897.59 372.26 876.40 376.55
  Q 875.25 376.78 873.73 376.22
  A 0.83 0.82 25.9 0 1 873.31 375.02
  Q 873.94 373.96 875.08 373.11
  C 884.34 366.20 895.02 362.87 906.39 363.70
  C 915.70 364.37 923.54 367.23 931.63 371.46
  Q 945.77 378.85 961.48 377.60
  C 963.97 377.40 966.39 377.23 968.85 377.01
  Z"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={redirect}>{logo}</RouterLink>;
}
