// @mui

import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }) {
  

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 462.97 342.79">
        <defs>
          <clipPath id="clippath">
            <path
              className="cls-16"
              d="M422.31,221.01s-42.64,18.54-54.89,34.74c-12.24,16.19-2.19,35.4-2.19,35.4,0,0,20.85,5.94,34.22-9.34,13.37-15.28,22.85-60.8,22.85-60.8Z"
            />
          </clipPath>
          <clipPath id="clippath-1">
            <path
              className="cls-16"
              d="M457.11,262.12s-44.36,.35-62.98,8.42c-18.62,8.06-20.94,25.26-20.94,25.26,0,0,13.56,10.82,32.59,3.77,19.03-7.05,51.33-37.45,51.33-37.45Z"
            />
          </clipPath>
          <clipPath id="clippath-2">
            <path
              className="cls-16"
              d="M12.46,169.82s26.2,38.42,44.39,47.42c18.2,9,35.19-4.48,35.19-4.48,0,0,1.93-21.6-15.58-31.87s-64-11.07-64-11.07Z"
            />
          </clipPath>
          <clipPath id="clippath-3">
            <path
              className="cls-16"
              d="M46.33,127.95s8.65,43.51,20.05,60.29c11.41,16.79,28.73,15.85,28.73,15.85,0,0,8.09-15.34-2.39-32.72s-46.39-43.42-46.39-43.42Z"
            />
          </clipPath>
        </defs>
        <g id="BACKGROUND">
          <g>
            <polygon
              className="cls-20"
              points="148.52 93.43 142.27 93.43 142.27 87.18 137.83 87.18 137.83 93.43 131.58 93.43 131.58 97.87 137.83 97.87 137.83 104.12 142.27 104.12 142.27 97.87 148.52 97.87 148.52 93.43"
            />
            <polygon
              className="cls-20"
              points="53.94 39.57 49.41 39.57 49.41 35.04 46.19 35.04 46.19 39.57 41.66 39.57 41.66 42.79 46.19 42.79 46.19 47.33 49.41 47.33 49.41 42.79 53.94 42.79 53.94 39.57"
            />
            <polygon
              className="cls-20"
              points="137.73 13.81 133.19 13.81 133.19 9.27 129.97 9.27 129.97 13.81 125.44 13.81 125.44 17.02 129.97 17.02 129.97 21.56 133.19 21.56 133.19 17.02 137.73 17.02 137.73 13.81"
            />
            <polygon
              className="cls-20"
              points="417.09 97.06 412.56 97.06 412.56 92.52 409.34 92.52 409.34 97.06 404.81 97.06 404.81 100.28 409.34 100.28 409.34 104.81 412.56 104.81 412.56 100.28 417.09 100.28 417.09 97.06"
            />
            <polygon
              className="cls-20"
              points="432.99 66.18 426.74 66.18 426.74 59.93 422.31 59.93 422.31 66.18 416.06 66.18 416.06 70.62 422.31 70.62 422.31 76.87 426.74 76.87 426.74 70.62 432.99 70.62 432.99 66.18"
            />
            <polygon
              className="cls-20"
              points="37.99 108.28 31.74 108.28 31.74 102.03 27.31 102.03 27.31 108.28 21.06 108.28 21.06 112.71 27.31 112.71 27.31 118.96 31.74 118.96 31.74 112.71 37.99 112.71 37.99 108.28"
            />
            <polygon
              className="cls-20"
              points="446.54 234.52 442.01 234.52 442.01 229.99 438.79 229.99 438.79 234.52 434.26 234.52 434.26 237.74 438.79 237.74 438.79 242.28 442.01 242.28 442.01 237.74 446.54 237.74 446.54 234.52"
            />
          </g>
          <g>
            <circle className="cls-6" cx="163.75" cy="3.91" r="3.91" />
            <path
              className="cls-6"
              d="M325.65,18.24c0,1.14-.92,2.06-2.06,2.06s-2.06-.92-2.06-2.06,.92-2.06,2.06-2.06,2.06,.92,2.06,2.06Z"
            />
            <path
              className="cls-6"
              d="M414.86,29.44c0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91,1.75-3.91,3.91-3.91,3.91,1.75,3.91,3.91Z"
            />
            <path
              className="cls-6"
              d="M23.12,147.19c0,1.14-.92,2.06-2.06,2.06s-2.06-.92-2.06-2.06,.92-2.06,2.06-2.06,2.06,.92,2.06,2.06Z"
            />
            <path
              className="cls-6"
              d="M461.02,218.37c0,2.16-1.75,3.91-3.91,3.91s-3.91-1.75-3.91-3.91,1.75-3.91,3.91-3.91,3.91,1.75,3.91,3.91Z"
            />
            <circle className="cls-6" cx="24.96" cy="71.84" r="3.91" />
          </g>
        </g>
        <g id="OBJECTS">
          <ellipse className="cls-9" cx="231.48" cy="311.94" rx="231.48" ry="30.85" />
          <g>
            <g>
              <g>
                <g>
                  <path
                    className="cls-18"
                    d="M422.31,221.01s-42.64,18.54-54.89,34.74c-12.24,16.19-2.19,35.4-2.19,35.4,0,0,20.85,5.94,34.22-9.34,13.37-15.28,22.85-60.8,22.85-60.8Z"
                  />
                  <g className="cls-15">
                    <path
                      className="cls-21"
                      d="M433.87,247.58c1.76-.27,3.51-.54,5.27-.81,.9-.14,.76-1.56-.15-1.42-12.4,1.91-24.79,3.83-37.19,5.74-1.16,.18-2.32,.36-3.48,.54,.97-1.19,1.93-2.37,2.9-3.56,3.38-4.15,6.76-8.31,10.14-12.46,12.29-1.9,24.59-3.79,36.88-5.69,1.76-.27,3.51-.54,5.27-.81,.9-.14,.76-1.56-.15-1.42l-37.19,5.74c-1.16,.18-2.32,.36-3.48,.54,1.87-2.3,3.74-4.6,5.61-6.9,1.89-2.32,3.77-4.63,5.66-6.95,.86-1.06,1.94-2.11,2.67-3.28,.03-.05,.08-.1,.12-.15,.58-.71-.52-1.61-1.1-.9-2.26,2.78-4.53,5.57-6.79,8.35-2.42,2.98-4.84,5.95-7.26,8.93-.62-11.92-1.23-23.84-1.85-35.77-.09-1.78-.18-3.55-.28-5.33-.05-.92-1.46-.77-1.42,.15,.65,12.53,1.3,25.05,1.94,37.58,.09,1.67,.17,3.34,.26,5.02-2.57,3.16-5.15,6.33-7.72,9.49-1.77,2.18-3.54,4.35-5.31,6.53l-1.85-35.77c-.09-1.78-.18-3.55-.28-5.33-.05-.92-1.46-.77-1.42,.15,.65,12.53,1.3,25.05,1.94,37.58,.09,1.67,.17,3.34,.26,5.02-4.34,5.34-8.69,10.68-13.03,16.02-.62-11.92-1.23-23.84-1.85-35.77-.09-1.78-.18-3.55-.28-5.33-.05-.92-1.46-.77-1.42,.15,.65,12.53,1.3,25.05,1.94,37.58,.09,1.67,.17,3.34,.26,5.02-4.34,5.34-8.69,10.68-13.03,16.02-.62-11.92-1.23-23.84-1.85-35.77-.09-1.78-.18-3.55-.28-5.33-.05-.92-1.46-.77-1.42,.15,.65,12.53,1.3,25.05,1.94,37.58,.09,1.67,.17,3.34,.26,5.02-.51,.63-1.03,1.26-1.54,1.89-1.89,2.32-3.77,4.63-5.66,6.95-.87,1.06-1.94,2.11-2.67,3.28-.03,.05-.08,.1-.12,.15-.58,.71,.52,1.61,1.1,.9,2.26-2.78,4.53-5.57,6.79-8.35,1.06-1.31,2.13-2.62,3.19-3.92,12.29-1.9,24.59-3.79,36.88-5.69,1.76-.27,3.51-.54,5.27-.81,.9-.14,.76-1.56-.15-1.42-12.4,1.91-24.79,3.83-37.19,5.74-1.16,.18-2.32,.36-3.48,.54,3.93-4.83,7.86-9.66,11.79-14.49,.41-.51,.83-1.02,1.24-1.53,12.29-1.9,24.59-3.79,36.88-5.69,1.76-.27,3.51-.54,5.27-.81,.9-.14,.76-1.56-.15-1.42-12.4,1.91-24.79,3.83-37.19,5.74-1.16,.18-2.32,.36-3.48,.54,4.34-5.34,8.69-10.68,13.03-16.02,12.29-1.9,24.59-3.79,36.88-5.69Z"
                    />
                  </g>
                </g>
                <path
                  className="cls-11"
                  d="M414.37,250.55c-1.44,2.04-2.95,4.06-4.58,6.06-14.12,17.35-31.73,28.24-46.33,30.14,.91,2.75,1.78,4.41,1.78,4.41,0,0,20.85,5.94,34.22-9.34,5.88-6.72,11.01-19.29,14.91-31.27Z"
                />
              </g>
              <g>
                <g>
                  <path
                    className="cls-21"
                    d="M457.11,262.12s-44.36,.35-62.98,8.42c-18.62,8.06-20.94,25.26-20.94,25.26,0,0,13.56,10.82,32.59,3.77,19.03-7.05,51.33-37.45,51.33-37.45Z"
                  />
                  <g className="cls-10">
                    <path
                      className="cls-24"
                      d="M452.01,285.16c1.56,.35,3.12,.69,4.68,1.04,.8,.18,1.46-.9,.65-1.08-11.01-2.44-22.02-4.89-33.03-7.33-1.03-.23-2.06-.46-3.09-.69,1.42-.57,2.84-1.14,4.26-1.71,4.97-1.99,9.94-3.99,14.91-5.98,10.92,2.42,21.84,4.85,32.75,7.27,1.56,.35,3.12,.69,4.68,1.04,.8,.18,1.46-.9,.65-1.08-11.01-2.44-22.02-4.89-33.03-7.33-1.03-.23-2.06-.46-3.09-.69,2.75-1.1,5.5-2.21,8.25-3.31,2.77-1.11,5.55-2.23,8.32-3.34,1.27-.51,2.7-.95,3.92-1.57,.06-.03,.12-.05,.18-.07,.85-.34,.45-1.34-.4-1-3.33,1.34-6.66,2.67-9.99,4.01-3.56,1.43-7.12,2.86-10.68,4.28,5.96-8.92,11.93-17.85,17.89-26.77,.89-1.33,1.78-2.66,2.67-3.99,.46-.69-.76-1.02-1.22-.33-6.27,9.38-12.53,18.75-18.8,28.13-.84,1.25-1.67,2.5-2.51,3.76-3.78,1.52-7.57,3.04-11.35,4.55-2.6,1.05-5.21,2.09-7.81,3.13,5.96-8.92,11.93-17.85,17.89-26.77,.89-1.33,1.78-2.66,2.67-3.99,.46-.69-.76-1.02-1.22-.33-6.27,9.38-12.53,18.75-18.8,28.13-.84,1.25-1.67,2.5-2.51,3.76-6.39,2.56-12.78,5.13-19.16,7.69,5.96-8.92,11.93-17.85,17.89-26.77,.89-1.33,1.78-2.66,2.67-3.99,.46-.69-.76-1.02-1.22-.33-6.27,9.38-12.53,18.75-18.8,28.13-.84,1.25-1.67,2.5-2.51,3.76-6.39,2.56-12.78,5.13-19.16,7.69,5.96-8.92,11.93-17.85,17.89-26.77,.89-1.33,1.78-2.66,2.67-3.99,.46-.69-.76-1.02-1.22-.33-6.27,9.38-12.53,18.75-18.8,28.13-.84,1.25-1.67,2.5-2.51,3.76-.76,.3-1.51,.61-2.27,.91-2.77,1.11-5.55,2.23-8.32,3.34-1.27,.51-2.7,.95-3.92,1.57-.06,.03-.12,.05-.18,.07-.85,.34-.45,1.34,.4,1,3.33-1.34,6.66-2.67,9.99-4.01,1.56-.63,3.13-1.26,4.69-1.88,10.92,2.42,21.84,4.85,32.75,7.27,1.56,.35,3.12,.69,4.68,1.04,.8,.18,1.46-.9,.65-1.08-11.01-2.44-22.02-4.89-33.03-7.33-1.03-.23-2.06-.46-3.09-.69,5.78-2.32,11.56-4.64,17.34-6.96,.61-.24,1.22-.49,1.83-.73,10.92,2.42,21.84,4.85,32.75,7.27,1.56,.35,3.12,.69,4.68,1.04,.8,.18,1.46-.9,.65-1.08-11.01-2.44-22.02-4.89-33.03-7.33-1.03-.23-2.06-.46-3.09-.69,6.39-2.56,12.78-5.13,19.16-7.69,10.92,2.42,21.84,4.85,32.75,7.27Z"
                    />
                  </g>
                </g>
                <path
                  className="cls-11"
                  d="M434.72,281.29c-2.26,1.05-4.58,2.06-6.96,3.02-20.76,8.33-40.83,10.84-53.6,7.7-.75,2.3-.96,3.78-.96,3.78,0,0,13.56,10.82,32.59,3.77,8.37-3.1,19.31-10.72,28.93-18.28Z"
                />
              </g>
            </g>
            <g>
              <g>
                <g>
                  <path
                    className="cls-18"
                    d="M12.46,169.82s26.2,38.42,44.39,47.42c18.2,9,35.19-4.48,35.19-4.48,0,0,1.93-21.6-15.58-31.87s-64-11.07-64-11.07Z"
                  />
                  <g className="cls-4">
                    <path
                      className="cls-21"
                      d="M36.4,153.5c-.6-1.68-1.19-3.35-1.79-5.03-.31-.86-1.67-.46-1.36,.41,4.2,11.82,8.4,23.64,12.59,35.46,.39,1.11,.79,2.21,1.18,3.32-1.35-.73-2.69-1.45-4.04-2.18-4.71-2.54-9.42-5.08-14.14-7.63-4.16-11.72-8.33-23.44-12.49-35.16-.6-1.68-1.19-3.35-1.79-5.03-.31-.86-1.67-.46-1.36,.41,4.2,11.82,8.4,23.64,12.59,35.46,.39,1.11,.79,2.21,1.18,3.32-2.61-1.41-5.22-2.81-7.82-4.22-2.63-1.42-5.26-2.84-7.89-4.26-1.21-.65-2.44-1.51-3.72-2.01-.06-.02-.11-.06-.17-.09-.81-.43-1.48,.82-.68,1.25,3.16,1.7,6.31,3.41,9.47,5.11,3.38,1.82,6.75,3.64,10.13,5.46-11.6,2.84-23.19,5.67-34.79,8.51-1.73,.42-3.45,.84-5.18,1.27-.89,.22-.48,1.58,.41,1.36,12.18-2.98,24.37-5.96,36.55-8.94,1.63-.4,3.25-.8,4.88-1.19,3.59,1.94,7.18,3.87,10.76,5.81,2.47,1.33,4.94,2.67,7.41,4-11.6,2.84-23.19,5.67-34.79,8.51-1.73,.42-3.45,.85-5.18,1.27-.89,.22-.48,1.58,.41,1.36,12.18-2.98,24.37-5.96,36.55-8.94,1.63-.4,3.25-.8,4.88-1.19,6.06,3.27,12.12,6.54,18.17,9.81-11.6,2.84-23.19,5.67-34.79,8.51-1.73,.42-3.45,.85-5.18,1.27-.89,.22-.48,1.58,.41,1.36,12.18-2.98,24.37-5.96,36.55-8.94l4.88-1.19c6.06,3.27,12.12,6.54,18.17,9.81-11.6,2.84-23.19,5.67-34.79,8.51l-5.18,1.27c-.89,.22-.48,1.58,.41,1.36l36.55-8.94c1.63-.4,3.25-.8,4.88-1.19,.72,.39,1.43,.77,2.15,1.16,2.63,1.42,5.26,2.84,7.89,4.26,1.21,.65,2.44,1.51,3.72,2.01,.06,.02,.11,.06,.17,.09,.81,.43,1.48-.82,.68-1.25-3.16-1.7-6.31-3.41-9.47-5.11-1.48-.8-2.97-1.6-4.45-2.4-4.16-11.72-8.33-23.44-12.49-35.16-.6-1.68-1.19-3.35-1.79-5.03-.31-.86-1.67-.46-1.36,.41,4.2,11.82,8.4,23.64,12.59,35.46,.39,1.11,.79,2.21,1.18,3.32-5.48-2.96-10.96-5.91-16.44-8.87-.58-.31-1.16-.62-1.73-.94-4.16-11.72-8.33-23.44-12.49-35.16-.6-1.68-1.19-3.35-1.79-5.03-.31-.86-1.67-.46-1.36,.41,4.2,11.82,8.4,23.64,12.59,35.46,.39,1.11,.79,2.21,1.18,3.32-6.06-3.27-12.12-6.54-18.17-9.81-4.16-11.72-8.33-23.44-12.49-35.16Z"
                    />
                  </g>
                </g>
                <path
                  className="cls-11"
                  d="M42.97,172.1c2.27,1.03,4.54,2.14,6.81,3.36,19.69,10.62,33.68,25.89,38.27,39.88,2.53-1.41,4-2.58,4-2.58,0,0,1.93-21.6-15.58-31.87-7.7-4.52-21.01-7.2-33.5-8.79Z"
                />
              </g>
              <g>
                <g>
                  <path
                    className="cls-21"
                    d="M46.33,127.95s8.65,43.51,20.05,60.29c11.41,16.79,28.73,15.85,28.73,15.85,0,0,8.09-15.34-2.39-32.72s-46.39-43.42-46.39-43.42Z"
                  />
                  <g className="cls-17">
                    <path
                      className="cls-24"
                      d="M69.92,128.65c.05-1.6,.1-3.2,.15-4.79,.02-.82-1.16-1.26-1.18-.44-.34,11.27-.68,22.54-1.02,33.82-.03,1.06-.06,2.11-.1,3.17-.82-1.29-1.65-2.58-2.47-3.86-2.89-4.51-5.78-9.02-8.67-13.52,.34-11.18,.68-22.36,1.02-33.54,.05-1.6,.1-3.2,.15-4.79,.02-.82-1.16-1.26-1.18-.44-.34,11.27-.68,22.54-1.02,33.82-.03,1.06-.06,2.11-.1,3.17-1.6-2.5-3.2-4.99-4.8-7.49-1.61-2.52-3.22-5.03-4.84-7.55-.74-1.15-1.44-2.48-2.28-3.56-.04-.05-.07-.11-.1-.16-.49-.77-1.4-.19-.91,.58,1.94,3.02,3.87,6.04,5.81,9.06,2.07,3.23,4.14,6.46,6.21,9.69-9.88-4.19-19.77-8.38-29.65-12.57-1.47-.62-2.94-1.25-4.42-1.87-.76-.32-.86,.94-.1,1.26,10.38,4.4,20.77,8.8,31.15,13.2,1.39,.59,2.77,1.18,4.16,1.76,2.2,3.43,4.4,6.87,6.6,10.3,1.51,2.36,3.03,4.73,4.54,7.09-9.88-4.19-19.77-8.38-29.65-12.57-1.47-.62-2.94-1.25-4.42-1.87-.76-.32-.86,.94-.1,1.26l31.15,13.2c1.39,.59,2.77,1.18,4.16,1.76l11.14,17.39c-9.88-4.19-19.77-8.38-29.65-12.57-1.47-.62-2.94-1.25-4.42-1.87-.76-.32-.86,.94-.1,1.26,10.38,4.4,20.77,8.8,31.15,13.2,1.39,.59,2.77,1.18,4.16,1.76,3.71,5.8,7.43,11.59,11.14,17.39-9.88-4.19-19.77-8.38-29.65-12.57-1.47-.62-2.94-1.25-4.42-1.87-.76-.32-.86,.94-.1,1.26,10.38,4.4,20.77,8.8,31.15,13.2,1.39,.59,2.77,1.18,4.16,1.76l1.32,2.06c1.61,2.52,3.22,5.03,4.84,7.55,.74,1.15,1.44,2.48,2.28,3.56,.04,.05,.07,.11,.1,.16,.49,.77,1.4,.19,.91-.58-1.94-3.02-3.87-6.04-5.81-9.06-.91-1.42-1.82-2.84-2.73-4.26,.34-11.18,.68-22.36,1.02-33.54,.05-1.6,.1-3.2,.15-4.79,.02-.82-1.16-1.26-1.18-.44-.34,11.27-.68,22.54-1.02,33.82-.03,1.06-.06,2.11-.1,3.17-3.36-5.24-6.72-10.49-10.08-15.73-.35-.55-.71-1.11-1.06-1.66,.34-11.18,.68-22.36,1.02-33.54,.05-1.6,.1-3.2,.15-4.79,.02-.82-1.16-1.26-1.18-.44-.34,11.27-.68,22.54-1.02,33.82-.03,1.06-.06,2.11-.1,3.17l-11.14-17.39c.34-11.18,.68-22.36,1.02-33.54Z"
                    />
                  </g>
                </g>
                <path
                  className="cls-11"
                  d="M69.36,146.36c1.45,2.02,2.88,4.11,4.27,6.28,12.07,18.84,18.29,38.08,17.59,51.22,2.4,.31,3.9,.23,3.9,.23,0,0,8.09-15.34-2.39-32.72-4.61-7.64-14.14-16.96-23.37-25Z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                className="cls-23"
                d="M87.08,113.24H375.89c3.04,0,5.51,2.47,5.51,5.51v186.8c0,3.04-2.47,5.51-5.51,5.51H87.08c-3.04,0-5.51-2.47-5.51-5.51V118.75c0-3.04,2.47-5.51,5.51-5.51Z"
              />
              <rect className="cls-6" x="95.96" y="128.66" width="271.05" height="172.28" />
              <path
                className="cls-23"
                d="M62.09,292.95v15.92c0,3.42,2.8,6.23,6.23,6.23H394.65c3.42,0,6.23-2.8,6.23-6.23v-15.92H62.09Z"
              />
              <path
                className="cls-5"
                d="M194.6,292.24v11.35c0,1.01,.83,1.84,1.84,1.84h70.08c1.01,0,1.84-.83,1.84-1.84v-11.35h-73.77Z"
              />
            </g>
            <g>
              <g>
                <g>
                  <rect className="cls-1" x="111.43" y="143.34" width="235.15" height="135.96" />
                  <rect className="cls-20" x="111.43" y="143.34" width="235.15" height="13.1" />
                </g>
                <g>
                  <path className="cls-21" d="M122.86,150.19c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z" />
                  <path className="cls-18" d="M132.96,150.19c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z" />
                  <path className="cls-23" d="M143.05,150.19c0,1.66-1.34,3-3,3s-3-1.34-3-3,1.34-3,3-3,3,1.34,3,3Z" />
                </g>
              </g>
              <g>
                <path
                  className="cls-6"
                  d="M225.4,225.24c0-3.77,1.5-6.7,4.49-8.81l1.44-1c1.37-.89,2.32-1.62,2.85-2.19,.53-.57,.8-1.28,.8-2.13,0-2.14-1.79-3.21-5.37-3.21-1.66,0-3.38,.22-5.15,.67-1.77,.44-3.45,1.07-5.04,1.88v-8.53c3.4-1.55,7.2-2.33,11.41-2.33,4.54,0,8.14,.95,10.8,2.85,2.66,1.9,3.99,4.53,3.99,7.89,0,1.88-.42,3.41-1.27,4.57-.85,1.16-2.29,2.46-4.32,3.91-1.37,1-2.36,1.76-2.99,2.3-.63,.54-1.1,1.14-1.41,1.8-.31,.67-.47,1.48-.47,2.44v1.05h-9.75v-1.16Zm1.14,13.27c-.98-.98-1.47-2.19-1.47-3.63s.49-2.7,1.47-3.66c.98-.96,2.21-1.44,3.68-1.44s2.67,.49,3.68,1.47c1.01,.98,1.52,2.19,1.52,3.63s-.51,2.65-1.52,3.63c-1.02,.98-2.24,1.47-3.68,1.47s-2.71-.49-3.68-1.47Z"
                />
                <path
                  className="cls-6"
                  d="M231.48,265.54c-26.02,0-47.2-21.17-47.2-47.2s21.17-47.2,47.2-47.2,47.2,21.17,47.2,47.2-21.17,47.2-47.2,47.2Zm0-91.49c-24.42,0-44.29,19.87-44.29,44.29s19.87,44.29,44.29,44.29,44.29-19.87,44.29-44.29-19.87-44.29-44.29-44.29Z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                className="cls-21"
                d="M297.07,172.7h140.94c2.84,0,5.15,2.31,5.15,5.15v27.89c0,2.84-2.31,5.15-5.15,5.15h-140.94c-2.84,0-5.15-2.31-5.15-5.15v-27.89c0-2.84,2.31-5.15,5.15-5.15Z"
              />
              <rect className="cls-24" x="336.19" y="177.8" width="2.06" height="27.99" />
            </g>
            <g>
              <path
                className="cls-1"
                d="M325.65,197.35l-7.03-3.57c.5-1.43,.52-2.97,.04-4.43-.58-1.76-1.81-3.2-3.46-4.04-1.65-.84-3.54-.98-5.3-.41-1.76,.58-3.2,1.81-4.04,3.46-.84,1.66-.98,3.54-.41,5.3,.58,1.76,1.81,3.2,3.46,4.04,1.65,.84,3.54,.98,5.3,.41,1.46-.48,2.69-1.41,3.55-2.65l7.03,3.57,.85-1.68Zm-15.88-1.32c-1.21-.61-2.1-1.66-2.52-2.94-.42-1.28-.31-2.66,.3-3.86,.61-1.21,1.66-2.1,2.94-2.52,1.28-.42,2.66-.32,3.86,.3h0c1.21,.61,2.1,1.66,2.52,2.94,.42,1.28,.31,2.66-.3,3.86-.61,1.21-1.66,2.1-2.94,2.52-1.28,.42-2.66,.31-3.86-.3Z"
              />
              <path
                className="cls-1"
                d="M309.04,189.56l1.01,.51c.51-1.01,1.75-1.42,2.76-.9l.51-1.01c-1.57-.8-3.49-.17-4.29,1.4Z"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect className="cls-22" x="24.96" y="214.5" width="138.79" height="88.29" />
                <rect className="cls-20" x="24.96" y="214.5" width="138.79" height="11.01" />
              </g>
              <g>
                <circle className="cls-21" cx="32.04" cy="220.26" r="2.52" />
                <path
                  className="cls-18"
                  d="M43.05,220.26c0,1.39-1.13,2.52-2.52,2.52s-2.52-1.13-2.52-2.52,1.13-2.52,2.52-2.52,2.52,1.13,2.52,2.52Z"
                />
                <circle className="cls-23" cx="49.02" cy="220.26" r="2.52" />
              </g>
            </g>
            <g>
              <g>
                <path
                  className="cls-24"
                  d="M45.4,262.94c2.04-2.46,4.08-4.39,6.12-6.14,2.04-1.78,4.08-3.28,6.12-4.69,4.08-2.76,8.16-4.91,12.24-6.55,8.16-3.31,16.32-4.74,24.47-4.78,8.16,0,16.32,1.41,24.47,4.71,4.08,1.64,8.16,3.79,12.24,6.56,2.04,1.42,4.08,2.92,6.12,4.71,2.04,1.76,4.08,3.7,6.12,6.18-2.04,2.48-4.08,4.41-6.12,6.18-2.04,1.79-4.08,3.29-6.12,4.71-4.08,2.76-8.16,4.92-12.24,6.56-8.16,3.3-16.32,4.72-24.47,4.71-8.16-.04-16.32-1.47-24.47-4.78-4.08-1.64-8.16-3.79-12.24-6.55-2.04-1.42-4.08-2.91-6.12-4.69-2.04-1.76-4.08-3.68-6.12-6.14Z"
                />
                <path
                  className="cls-21"
                  d="M106.26,264.18c-6.46,0-11.7-5.24-11.7-11.7,0-2.6,.86-4.99,2.29-6.93-.82-.12-1.65-.2-2.5-.2-9.72,0-17.59,7.88-17.59,17.59s7.88,17.59,17.59,17.59,17.59-7.88,17.59-17.59c0-.08-.01-.15-.01-.23-1.68,.93-3.61,1.47-5.67,1.47Z"
                />
                <path
                  className="cls-23"
                  d="M45.4,262.94c2.85-3.63,6.26-6.81,9.88-9.73,3.66-2.87,7.58-5.45,11.76-7.57,4.16-2.17,8.61-3.81,13.19-5.02,4.59-1.16,9.33-1.75,14.08-1.8,1.19,.04,2.38,0,3.56,.11l3.55,.33c2.34,.38,4.7,.69,6.98,1.35,2.32,.53,4.52,1.39,6.76,2.14l3.25,1.39,1.61,.73,1.55,.83c8.32,4.36,15.64,10.32,21.72,17.24-7.28-5.63-14.94-10.64-23.14-14.27l-1.53-.69-1.57-.58-3.13-1.18c-2.14-.61-4.25-1.34-6.43-1.73-2.15-.54-4.35-.82-6.54-1.14l-3.31-.26c-1.1-.09-2.21-.03-3.31-.06-8.84-.03-17.65,2.05-25.89,5.57-4.12,1.78-8.12,3.88-11.97,6.29-3.86,2.38-7.56,5.07-11.07,8.04Z"
                />
              </g>
              <rect
                className="cls-23"
                x="52.98"
                y="260.93"
                width="80.68"
                height="2.88"
                transform="translate(-158.19 142.83) rotate(-45)"
              />
            </g>
          </g>
          <g>
            <g>
              <g>
                <rect className="cls-22" x="174.46" y="24.74" width="138.79" height="88.29" />
                <rect className="cls-20" x="174.46" y="24.74" width="138.79" height="11.01" />
              </g>
              <g>
                <circle className="cls-25" cx="181.54" cy="30.5" r="2.52" />
                <path
                  className="cls-25"
                  d="M192.55,30.5c0,1.39-1.13,2.52-2.52,2.52s-2.52-1.13-2.52-2.52,1.13-2.52,2.52-2.52,2.52,1.13,2.52,2.52Z"
                />
                <circle className="cls-25" cx="198.52" cy="30.5" r="2.52" />
              </g>
            </g>
            <g>
              <g>
                <rect className="cls-22" x="162.09" y="45.35" width="138.79" height="88.29" />
                <rect className="cls-20" x="162.09" y="45.35" width="138.79" height="11.01" />
              </g>
              <g>
                <circle className="cls-21" cx="169.18" cy="51.11" r="2.52" />
                <path
                  className="cls-18"
                  d="M180.18,51.11c0,1.39-1.13,2.52-2.52,2.52s-2.52-1.13-2.52-2.52,1.13-2.52,2.52-2.52,2.52,1.13,2.52,2.52Z"
                />
                <circle className="cls-23" cx="186.15" cy="51.11" r="2.52" />
              </g>
            </g>
            <g>
              <path
                className="cls-21"
                d="M256.6,120h-49.98c-2.82,0-5.04-1.13-6.11-3.09-1.06-1.96-.79-4.44,.75-6.8h0s24.34-37.3,24.34-37.3c1.49-2.28,3.68-3.59,6-3.59s4.51,1.31,6,3.59l24.34,37.3c1.54,2.36,1.81,4.84,.75,6.8-1.06,1.96-3.29,3.09-6.11,3.09Zm-50.91-7c-.55,.84-.55,1.32-.52,1.41,.05,.05,.46,.31,1.46,.31h49.98c1,0,1.41-.26,1.46-.33,.02-.07,.01-.55-.53-1.39l-24.34-37.3c-.49-.75-1.08-1.2-1.58-1.2s-1.09,.45-1.58,1.2l-24.34,37.3Z"
              />
              <path
                className="cls-21"
                d="M230.08,109.89c-.4-.4-.6-.9-.6-1.5s.2-1.09,.6-1.5c.4-.4,.9-.61,1.5-.61s1.1,.2,1.51,.61c.41,.4,.62,.9,.62,1.5s-.21,1.09-.62,1.5c-.42,.4-.92,.61-1.51,.61s-1.09-.2-1.5-.61Zm-1.25-24.82h5.55l-1.03,17.77h-3.49l-1.03-17.77Z"
              />
            </g>
          </g>
          <g>
            <path
              className="cls-18"
              d="M383.83,41.02c-5.69-5.49-13.34-8.54-21.23-8.59-8.17,.05-16.11,3.32-21.83,9.18-5.8,5.94-9.01,14.17-8.68,22.47,.21,5.32,1.82,10.52,4.63,15.03-1.03,4-2.05,8-3.08,12-.49,1.92,1.25,3.66,3.17,3.17,4.48-1.14,8.97-2.28,13.45-3.42,7.04,3.02,15.04,3.52,22.32,.96,6.5-2.29,12.08-6.69,15.8-12.49,7.71-12.03,5.71-28.41-4.55-38.31Zm3.4,28.08c-1.88,7.5-7.13,13.72-14.12,16.99-3.56,1.66-7.53,2.37-11.44,2.24-2.56-.08-5.06-.57-7.48-1.41-1.6-.55-3.03-1.56-4.75-1.16-3.26,.75-6.5,1.6-9.74,2.45,.35-1.37,.71-2.75,1.06-4.12,.37-1.44,.77-2.88,1.11-4.33,.19-.82,.26-1.56-.16-2.36-.78-1.49-1.77-2.83-2.44-4.39-4.51-10.41-1.29-22.64,7.37-29.79,4.47-3.68,10.21-5.59,15.96-5.63,7.8,.05,15.27,3.63,20.07,9.81,4.73,6.09,6.44,14.23,4.57,21.7Z"
            />
            <path
              className="cls-13"
              d="M362.56,45.88c-9.42,0-17.08,7.66-17.08,17.08s7.66,17.08,17.08,17.08,17.08-7.66,17.08-17.08-7.66-17.08-17.08-17.08Zm-12.05,17.08c0-6.64,5.41-12.05,12.05-12.05,2.36,0,4.6,.67,6.55,1.94l-16.66,16.66c-1.27-1.95-1.94-4.19-1.94-6.55Zm12.05,12.05c-2.36,0-4.6-.67-6.55-1.94l16.66-16.66c1.27,1.95,1.94,4.19,1.94,6.55,0,6.64-5.41,12.05-12.05,12.05Z"
            />
          </g>
          <g>
            <g>
              <path
                className="cls-18"
                d="M439.77,139.8c-1.02-1.25-2.02-2.15-2.77-2.8-.97-.85-1.93-1.55-2.76-2.12-1.77-1.2-3.62-2.19-5.5-2.95-3.48-1.41-7.17-2.12-11.01-2.12-3.88,.02-7.48,.72-10.99,2.15-1.89,.76-3.74,1.75-5.51,2.95-.81,.56-1.76,1.25-2.74,2.1-.76,.65-1.76,1.56-2.78,2.79l-.64,.77,.64,.77c1.02,1.23,2.02,2.14,2.77,2.79,.98,.86,1.94,1.55,2.76,2.11,1.76,1.19,3.61,2.18,5.5,2.94,3.52,1.43,7.12,2.13,11,2.15h.03c3.8,0,7.49-.71,10.97-2.12,1.88-.75,3.73-1.75,5.52-2.96,.81-.57,1.78-1.26,2.73-2.11,.76-.65,1.75-1.56,2.78-2.81l.63-.77-.63-.77Zm-4.37,2.56c-.88,.77-1.77,1.41-2.52,1.93-1.63,1.1-3.33,2-5.05,2.69-3.23,1.3-6.52,1.93-10.09,1.93-3.57-.02-6.87-.66-10.1-1.96-1.73-.69-3.42-1.59-5.04-2.68-.75-.52-1.64-1.15-2.54-1.94-.5-.43-1.14-1-1.82-1.72,.68-.73,1.32-1.3,1.82-1.73,.89-.77,1.77-1.41,2.52-1.92,1.62-1.09,3.32-2,5.05-2.69,3.23-1.3,6.53-1.94,10.12-1.96,3.54,0,6.83,.63,10.06,1.93,1.72,.69,3.41,1.59,5.03,2.68,.76,.53,1.65,1.17,2.54,1.95,.5,.43,1.14,1,1.82,1.74-.68,.74-1.33,1.31-1.83,1.74Z"
              />
              <path
                className="cls-18"
                d="M424.5,139.08l-1.22,.68c-.46,.26-.98,.39-1.51,.39-1.72,0-3.12-1.4-3.12-3.12,0-.67,.21-1.31,.61-1.85l.83-1.13-1.39-.2c-.36-.05-.67-.08-.96-.08-3.74,0-6.79,3.04-6.79,6.79s3.04,6.79,6.79,6.79,6.79-3.04,6.79-6.79c0-.03,0-.07,0-.09l-.02-1.4Z"
              />
            </g>
            <rect
              className="cls-21"
              x="398.44"
              y="139.66"
              width="38.59"
              height="1.82"
              transform="translate(22.96 336.55) rotate(-45)"
            />
          </g>
          <g>
            <path
              className="cls-23"
              d="M86.15,87.89c-.95,0-1.81,.18-2.51,.47-.27-1.68-.93-3.23-1.89-4.55,.7-.28,1.44-.76,2.11-1.43,1.59-1.59,2.18-3.59,1.31-4.47-.87-.87-2.87-.29-4.47,1.31-.67,.67-1.15,1.41-1.43,2.11-1.32-.95-2.87-1.61-4.55-1.89,.3-.69,.47-1.56,.47-2.51,0-2.26-1-4.08-2.23-4.08s-2.23,1.83-2.23,4.08c0,.95,.18,1.81,.47,2.51-1.68,.27-3.23,.93-4.55,1.89-.28-.7-.76-1.44-1.43-2.11-1.59-1.59-3.59-2.18-4.47-1.31-.87,.87-.29,2.87,1.31,4.47,.67,.67,1.41,1.15,2.11,1.43-.95,1.32-1.61,2.87-1.88,4.55-.69-.3-1.56-.47-2.51-.47-2.25,0-4.08,1-4.08,2.23s1.83,2.23,4.08,2.23c.95,0,1.81-.18,2.51-.47,.27,1.68,.93,3.23,1.88,4.55-.7,.28-1.44,.76-2.11,1.43-1.59,1.59-2.18,3.59-1.31,4.47,.87,.87,2.87,.29,4.47-1.31,.67-.67,1.15-1.41,1.43-2.11,1.32,.95,2.87,1.61,4.55,1.89-.3,.69-.47,1.56-.47,2.51,0,2.25,1,4.08,2.23,4.08s2.23-1.83,2.23-4.08c0-.95-.18-1.82-.47-2.51,1.68-.27,3.23-.93,4.55-1.89,.28,.7,.76,1.44,1.43,2.11,1.59,1.59,3.59,2.18,4.47,1.31s.29-2.87-1.31-4.47c-.67-.67-1.41-1.15-2.11-1.43,.95-1.32,1.61-2.87,1.89-4.55,.69,.3,1.56,.47,2.51,.47,2.25,0,4.08-1,4.08-2.23s-1.83-2.23-4.08-2.23Zm-13.18,9.35c-3.93,0-7.12-3.19-7.12-7.12s3.19-7.12,7.12-7.12,7.12,3.19,7.12,7.12-3.19,7.12-7.12,7.12Z"
            />
            <path
              className="cls-23"
              d="M121.48,63.87c-1.27-.51-2.54-.74-3.63-.72,.54-2.41,.49-4.85-.07-7.15,1.1,0,2.35-.24,3.62-.78,3.01-1.28,4.88-3.66,4.18-5.31s-3.71-1.94-6.72-.66c-1.27,.54-2.31,1.28-3.07,2.07-1.27-2-2.99-3.72-5.11-5.01,.77-.77,1.48-1.85,2-3.12,1.22-3.04,.87-6.04-.79-6.71-1.66-.67-4,1.25-5.22,4.29-.51,1.27-.74,2.54-.72,3.63-2.41-.54-4.85-.49-7.15,.07,0-1.1-.24-2.35-.78-3.62-1.28-3.01-3.66-4.88-5.31-4.18-1.65,.7-1.94,3.71-.66,6.72,.54,1.27,1.28,2.31,2.07,3.07-2,1.27-3.73,2.99-5.01,5.11-.77-.77-1.85-1.48-3.12-2-3.04-1.22-6.04-.87-6.71,.79-.67,1.66,1.25,4,4.29,5.22,1.27,.51,2.54,.74,3.63,.72-.54,2.41-.49,4.85,.07,7.15-1.1,0-2.35,.24-3.62,.78-3.01,1.28-4.88,3.66-4.18,5.31,.7,1.65,3.71,1.94,6.72,.66,1.27-.54,2.31-1.28,3.07-2.07,1.27,2,2.99,3.73,5.11,5.01-.77,.77-1.48,1.85-2,3.12-1.22,3.04-.87,6.04,.79,6.71,1.66,.67,4-1.25,5.22-4.29,.51-1.27,.74-2.54,.72-3.63,2.41,.54,4.85,.49,7.15-.07,0,1.1,.24,2.35,.78,3.62,1.28,3.01,3.66,4.89,5.31,4.18,1.65-.7,1.94-3.71,.66-6.72-.54-1.27-1.28-2.31-2.07-3.07,2-1.27,3.73-2.99,5.01-5.11,.77,.77,1.85,1.48,3.12,2,3.04,1.22,6.04,.87,6.71-.79,.67-1.66-1.25-4-4.29-5.22Zm-22.82,5.45c-5.29-2.13-7.86-8.15-5.73-13.44,2.13-5.29,8.15-7.86,13.44-5.73,5.29,2.13,7.86,8.15,5.73,13.44-2.13,5.29-8.15,7.86-13.44,5.73Z"
            />
          </g>
          <path
            className="cls-19"
            d="M320.25,93.96c0,1.14-.92,2.06-2.06,2.06s-2.06-.92-2.06-2.06,.92-2.06,2.06-2.06,2.06,.92,2.06,2.06Z"
          />
          <g>
            <path
              className="cls-24"
              d="M282.2,313.03c-.88,1.35-.89,2.14-.85,2.27,.07,.08,.74,.5,2.35,.5h80.78c1.61,0,2.28-.42,2.37-.53,.03-.11,.02-.89-.86-2.25l-39.34-60.28c-.79-1.21-1.74-1.93-2.55-1.93s-1.76,.72-2.55,1.93l-39.34,60.28Z"
            />
            <g>
              <path
                className="cls-21"
                d="M364.48,324.35h-80.78c-4.55,0-8.15-1.82-9.87-4.99-1.72-3.17-1.28-7.18,1.21-10.99h0s39.35-60.28,39.35-60.28c2.41-3.69,5.94-5.81,9.7-5.81s7.29,2.12,9.7,5.81l39.35,60.28c2.49,3.81,2.93,7.82,1.21,10.99-1.72,3.17-5.32,4.99-9.87,4.99Zm-82.28-11.32c-.88,1.35-.89,2.14-.85,2.27,.07,.08,.74,.5,2.35,.5h80.78c1.61,0,2.28-.42,2.37-.53,.03-.11,.02-.89-.86-2.25l-39.34-60.28c-.79-1.21-1.74-1.93-2.55-1.93s-1.76,.72-2.55,1.93l-39.34,60.28Z"
              />
              <path
                className="cls-21"
                d="M321.61,308.01c-.65-.65-.98-1.46-.98-2.42s.33-1.76,.98-2.42c.65-.65,1.46-.98,2.42-.98s1.77,.33,2.45,.98c.67,.65,1.01,1.46,1.01,2.42s-.34,1.76-1.01,2.42c-.67,.65-1.49,.98-2.45,.98s-1.76-.33-2.42-.98Zm-2.01-40.11h8.98l-1.67,28.72h-5.64l-1.67-28.72Z"
              />
            </g>
          </g>
        </g>
        <g id="TEXTS">
          <text className="cls-2" transform="translate(351.34 196.57)">
            <tspan className="cls-8" x="0" y="0">
              NO{' '}
            </tspan>
            <tspan className="cls-3" x="22.99" y="0">
              D
            </tspan>
            <tspan className="cls-7" x="32.29" y="0">
              A
            </tspan>
            <tspan className="cls-12" x="40.15" y="0">
              T
            </tspan>
            <tspan className="cls-14" x="46.6" y="0">
              A
            </tspan>
          </text>
        </g>
      </svg>
    </Box>
  );
}
